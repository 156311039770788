<template>
  <v-dialog
    v-model="dialog"
    :width="width"
    persistent
  >
    <v-card>
      <v-card-text>
        Vous allez fermer le formulaire en cours. <br>
        <span class="font-weight-bold">Toutes les données modifiées seront perdues. </span><br>
        Souhaitez-vous continuer ?
      </v-card-text>
      <v-card-actions>

        <v-row class="pr-2 py-2 pl-1">
            <v-col cols="6" class="py-0">
              <v-btn variant="outlined"
                text="Annuler" block
                @click="dialog = false;"
              />
            </v-col>
            <v-col cols="6" class="text-right py-0">
              <v-btn
                color="danger" variant="flat" block
                text="Confirmer"
                @click="dialog = false; $emit('closeModal')"
              >
              </v-btn>
            </v-col>
          </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
export default {
    name : "ModalPreventClose",
    props : {
        width : { type: Number, required : false, default : 550 },
    },
    emits: ['closeModal'],
    data () {
        return {
            dialog : true,
        }
    },
}
</script>
