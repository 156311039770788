<template>
    <!-- EDIT --> 
    <v-tooltip v-if="!editDisabled" text="Modifier">
        <template #activator="{ props }">
            <v-btn
            v-if="$permissionCheck(`${permissionName}_update`)"
                v-bind="props"
                icon="mdi-square-edit-outline"
                aria-label="Modifier"
                class="mr-2"
                color="secondary"
                variant="tonal"
                size="x-small"
                @click.prevent="$emit('redirectEdit')"
            />
        </template>
    </v-tooltip>
    <!-- CUSTOM -->
    <slot name="customButtons" :item="data" />
    <!-- DELETE -->
    <v-tooltip v-if="!deleteDisabled && translationName" :text="$translate(`${translationName}.delete`)">
        <template #activator="{ props }">
            <span v-bind="props">
            <ListDeleteConfirmation
                v-if="data && $permissionCheck(`${permissionName}_destroy`)"
                :item="data"
                :translation-name="translationName" 
                :button="`<v-btn class='mr-2' icon='mdi-archive' variant='tonal' color='danger' size='x-small' aria-label='Supprimer'/>`" 
                @delete-data="$emit('deleteData', $event); $emit('redirectTable')"
            />
            </span>
        </template>
    </v-tooltip>
  </template>
  
  <script lang="ts">
  export default {
      name:"TopButtons",
      emits: ['closeModal', 'redirectEdit', 'deleteData', 'redirectTable'],
    props : {
        defaultUrl : { type : String, required : false, default : "" },
          permissionName : { type : String, required : false, default : "" },
          translationName : { type : String, required : false, default : "" },
          data : { type : Object, required : false, default : () => ({}) },
          isForm : { type : Boolean },
          editDisabled : { type : Boolean, required : false },
          deleteDisabled : { type : Boolean, required : false },
    }
  }
  </script>
  