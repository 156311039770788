<template>
    <v-row class="pr-2 py-2 pl-1">
        <v-col cols="6">
            <v-btn
            :color="customButtonColor" 
            :variant="customButtonBlock ? 'outlined' : 'text'"
            @click.prevent="$emit('closeModal')" :block="customButtonBlock"
            >
            {{ isForm ? 'Annuler' : 'Fermer' }}
            </v-btn>
        </v-col>
        <v-col cols="6" class="text-right">
            <v-btn v-if="currentKey && !isForm && !editDisabled"
                :color="customButtonColor" 
                :variant="customButtonBlock ? 'outlined' : 'text'"
                :block="customButtonBlock"
                @click.prevent="$emit('redirectEdit')"
            >
                Modifier
            </v-btn>
            <v-btn
                v-if="isForm" 
                :block="customButtonBlock"
                :color="customButtonColor"
                :disabled="disableSaveButton"
                :loading="saving"
                variant="flat"
                @click.prevent="$emit('save')"
            >
                {{ saveText }}
            </v-btn>
        </v-col>
    </v-row>
  </template>
  
  <script lang="ts">
  export default {
      name:"BottomButtons",
      emits: ['save', 'closeModal', 'redirectEdit'],
      props : {
          isForm : { type : Boolean },
          saving : { type : Boolean },
          editDisabled : { type : Boolean, required : false },
          deleteDisabled : { type : Boolean, required : false },
          saveText : { type : String, required : false, default : "Sauvegarder" },
          customButtonColor : { type : String, required : false, default : "secondary" },
          customButtonBlock : { type : Boolean, required : false },
          disableSaveButton : { type : Boolean, required : false },
          currentKey: { type : String, required : false, default : "" },
      },
  }
  </script>
  